'use client';

import {useContext, useEffect, useState} from 'react';
import {Card} from '@nextui-org/react';
import {Team} from '@shared/api/graphql';
import {getAllTeams} from '../services/api';
import {LoaderContext} from '@/context/LoaderContext';
import Button from '../components/Button/Button';
import CityIDInput from '@/components/CityIDInput';
import {SearchIcon} from '@/components/icons/SearchIcon';
import {useRouter} from 'next/navigation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';

export default function Home() {
  const loader = useContext(LoaderContext);
  const router = useRouter();
  const [teams, setTeams] = useState<Team[]>([]);
  const [teamsFilter, setTeamsFilter] = useState('');
  const [filteredTeams, setFilteredTeams] = useState<Team[]>([]);

  const filterTeams = (filter: string) => {
    if (!filter.trim().length) {
      setFilteredTeams(teams);
    } else {
      setFilteredTeams(
        teams.filter(team =>
          team.name.toLowerCase().includes(filter.toLowerCase())
        )
      );
    }
  };

  const loadTeams = () => {
    loader.showLoader();
    getAllTeams()
      .then(teams => {
        if (!teams) throw new Error('Unable to load Teams.');
        setTeams(teams);
      })
      .finally(() => loader.hideLoader());
  };

  useEffect(() => {
    filterTeams(teamsFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsFilter, teams]);

  const viewTeam = (teamId: string) => {
    router.push(`/team/${teamId}`);
  };

  useEffect(() => {
    loadTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-gray dark:bg-black h-full">
      <div className="p-4 bg-gray dark:bg-black container mt-4 ml-16 mt-4 mr-32 ">
        <div className="mb-4 grid grid-cols-6 gap-3">
          <h1 className="text-2xl font-bold leading-none tracking-tight col-span-2">
            Teams
          </h1>
          <div className="col-span-2"></div>
          <CityIDInput
            className="col-span-2"
            placeholder="Search Team"
            labelPlacement="outside"
            value={teamsFilter}
            onValueChange={setTeamsFilter}
            onAccept={filterTeams}
            startContent={
              <FontAwesomeIcon
                className="text-secondary text-xl"
                icon={faMagnifyingGlass}
              />
            }
          />
        </div>

        <div className="max-w-50 m-auto mt-8">
          {teams.length === 0 && (
            <Card className="mt-4 mb-4 p-8 gap-3 text-xl font-bold">
              Create a team to get started.
            </Card>
          )}

          {teams.length > 0 && filteredTeams.length === 0 && (
            <Card className="mt-4 mb-4 p-8 gap-3 text-xl font-bold">
              Nothing to see here.
            </Card>
          )}

          {filteredTeams.map((team: Team) => (
            <Card
              key={team.id}
              className="mt-4 mb-4 p-4 grid grid-cols-4 gap-3"
            >
              <span className="mb-4 text-xl font-bold text-secondary col-span-3">
                {team.name}
              </span>
              <Button
                label="View"
                onClick={() => viewTeam(team.id)}
                variant="secondary"
              />
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}
